<template>
  <div class="footer" :class="classBg">
    <div class="container">
      <template v-if="!$root.isMobile && !$root.isTablet">
        <div class="footer__top">
          <div class="footer__social-links">
            <a
              v-for="(link, i) in social_links"
              :key="i"
              class="footer__social-link"
              :href="link.href"
              target="_blank"
            >
              <svg width="45" height="45" aria-hidden="true">
                <use :xlink:href="link.icon"></use>
              </svg>
            </a>
          </div>
        </div>
        <div class="footer__copyright">
          <div class="footer__copyright-left">
            <div>
              <span> Verified Smart contract: </span>
              <a
                href="https://etherscan.io/address/0x10A0cF0Fd3B9b2d575D78130B29d61252313423E"
                target="_blank"
              >
                0x10A0cF0Fd3B9b2d575D78130B29d61252313423E
              </a>
            </div>
            <span>{{ copyright.text }}</span>
          </div>
          <a :href="'mailto:' + copyright.email">{{ copyright.email }}</a>
        </div>
      </template>
      <template v-else>
        <div class="footer__copyright">
          <p>
            COPYRIGHT © 2021 <br />
            ALL RIGHTS RESERVED
          </p>
          <p><a href="mailto:survivors@m101.space">survivors@m101.space</a></p>
        </div>
        <div class="footer__social-links">
          <a
            v-for="(link, i) in social_links"
            :key="i"
            class="footer__social-link"
            :href="link.href"
            target="_blank"
          >
            <svg width="45" height="45" aria-hidden="true">
              <use :xlink:href="link.icon"></use>
            </svg>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  props: {},
  data() {
    return {
      social_links: [
        {
          href: 'https://twitter.com/M101_Shelter',
          icon: '#twitter'
        },
        {
          href: 'https://discord.com/invite/m101',
          icon: '#discord'
        },
        {
          href: 'https://opensea.io/collection/m101survivors',
          icon: '#opensea'
        }
      ],
      copyright: {
        text: 'copyright © 2021 all rights reserved',
        email: 'survivors@m101.space'
      }
    }
  },
  computed: {
    classBg() {
      return 'bg-dark'
    }
  }
}
</script>
