/* eslint-disable prettier-vue/prettier */
import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/views/HomePage'
import ErrorPage from '@/views/ErrorPage'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/*',
      name: '404',
      component: ErrorPage,
      meta: {
        title: '404'
      }
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
