<template>
  <main class="bg-main">
    <Header :is-wallet-connected="isWalletConnected" @connectMetaMask="connectMetaMask" />
    <Main
      :current-wallet="currentWallet"
      :is-wallet-connected="isWalletConnected"
      @connectMetaMask="connectMetaMask"
    />
    <Footer />
  </main>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Main from '@/components/Main'

export default {
  name: 'HomePage',
  components: {
    Header,
    Main,
    Footer
  },
  props: {
    currentWallet: {
      type: String,
      default: null
    },
    isWalletConnected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {}
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  computed: {},
  mounted() {},
  methods: {
    connectMetaMask() {
      this.$emit('connectMetaMask')
    }
  }
}
</script>
