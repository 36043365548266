<template>
  <div v-if="showMinting && !soldOut" class="minting__wrapper" :style="style">
    <div class="minting__title-main">
      <h1>ALL-STAR COLLECTION MINTING IS LIVE!</h1>
      <h2>ALL M101 HOLDERS HAVE A PRIVILEGE TO MINT THIS UNIQUE</h2>
      <h2>1/1 HAND-DRAWN NFT ABSOLUTELY FOR FREE!!!</h2>
    </div>
    <div class="minting__wrap">
      <div class="minting__img">
        <img src="@/assets/img/allstars.png" alt="mint" />
      </div>
      <div class="minting__mint bg-pink">
        <span class="minting__mint-bg"></span>
        <span class="minting__mint-bg"></span>

        <h2 class="minting__title">MAX 1 NFT PER ADDRESS</h2>

        <div class="minting__title-sep"></div>

        <h2 class="minting__title minting__title--small">
          <span>MINTED </span>
          <p>{{ computedMintedCount }} / {{ computedTotalCount }}</p>
        </h2>

        <div class="minting__btnwrap">
          <plus-minus-input
            :current-value.sync="currentCount"
            :max-value="maxMintCount"
            :min-value="minMintCount"
          />

          <button class="button button--yellow" @click="handleMint">
            <span></span><span></span><span></span><span></span><span></span><span></span>
            <template v-if="isFree">MINT FOR FREE </template>
            <template v-else> MINT </template>
            <!-- <template v-else> {{ currentPrice }} <small>Ξ</small> </template> -->
          </button>
        </div>
      </div>
    </div>

    <transaction :title.sync="title" :link.sync="link" :show-modal.sync="showModal" />
  </div>
</template>

<script>
import Web3 from 'web3'
import { contractAddress, abi } from '@/utils/allstars.js'
import { shelterContractAddress, shelterAbi } from '@/utils/shelter.js'
import PlusMinusInput from '@/components/PlusMinusInput'
import Transaction from '@/components/Transaction.vue'

const delimiter = 1e18
const unknown = 'xxxxx'

const infura = 'https://mainnet.infura.io/v3/4a91eb50e36d430681e0000756d6261b'

export default {
  name: 'Minting',
  components: { PlusMinusInput, Transaction },
  props: {
    currentWallet: {
      type: String,
      default: null
    },
    isWalletConnected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      web3: new Web3(Web3.givenProvider || infura),
      currentCount: 0,
      allstarsContract: null,
      shelterContract: null,
      mintedCount: null,
      totalCount: null,
      price: null,
      title: null,
      link: null,
      soldOut: false,
      showModal: false,
      showMinting: false,
      showCounter: true,
      countDown: {
        startTimePresale: new Date('2021-11-27T20:00:00.000+03:00').getTime(),
        endTimePresale: new Date('2021-11-28T20:00:00.000+03:00').getTime(),
        startTimeSale: new Date('2021-12-01T20:00:00.000+03:00').getTime(),
        hours: null,
        minutes: null,
        seconds: null
      },
      tokenID: 1,
      isActive: false,
      starBalance: 0,
      shelterBalance: 0
    }
  },
  computed: {
    style() {
      return !this.showCounter ? { justifyContent: 'center' } : {}
    },
    currentPrice() {
      return (this.currentCount * (this.price / delimiter)).toFixed(2)
    },
    computedLeftCount() {
      return this.mintedCount !== null ? this.totalCount - this.mintedCount : 0
    },
    computedMintedCount() {
      return this.mintedCount !== null ? this.mintedCount : unknown
    },
    computedTotalCount() {
      return this.totalCount !== null ? this.totalCount : unknown
    },
    computedCountDown() {
      const { hours, minutes, seconds } = this.countDown
      return {
        hours: this.formatNumber(hours),
        minutes: this.formatNumber(minutes),
        seconds: this.formatNumber(seconds)
      }
    },
    maxMintCount() {
      return 1
    },
    minMintCount() {
      return 0
    },
    isFree() {
      return (
        this.isWalletConnected &&
        (Number(this.price) === 0 || (this.shelterBalance > 0 && this.starBalance < 1))
      )
    }
  },
  watch: {
    currentWallet(newV, oldV) {
      this.web3Handler()
    }
  },
  async mounted() {
    this.handleTimerCountDown()
    setInterval(() => {
      this.handleTimerCountDown()
    }, 1000)

    await this.web3Handler()
  },
  methods: {
    async checkNetwork() {
      const netId = await this.web3.eth.getChainId().catch(error => {
        console.log(error)
      })

      switch (netId) {
        case 1:
          console.log('This is mainnet')
          break
        case 2:
          console.log('This is the deprecated Morden test network.')
          break
        case 3:
          console.log('This is the ropsten test network.')
          break
        default:
          console.log('This is an unknown network.')
      }

      if (netId !== 1 && netId !== '1') {
        this.web3 = new Web3(infura)

        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'You are on the wrong network. Please switch to Ethereum Mainnet!'
        })
      }

      return Promise.resolve()
    },
    handleTimerCountDown() {
      const currentTime = new Date().getTime()
      const distancePresaleStart = this.countDown.startTimePresale - currentTime
      const distancePresaleEnd = this.countDown.endTimePresale - currentTime
      const distanceSaleStart = this.countDown.startTimeSale - currentTime
      const presaleStart = distancePresaleStart <= 0 && distancePresaleEnd > 0
      // const presaleEnd = distancePresaleEnd <= 0
      const saleStart = distanceSaleStart <= 0 && distancePresaleEnd <= 0

      this.countDown.hours = Math.floor(
        ((presaleStart ? distancePresaleEnd : distancePresaleStart) % (1000 * 60 * 60 * 600)) /
          (1000 * 60 * 60)
      )
      this.countDown.minutes = Math.floor(
        ((presaleStart ? distancePresaleEnd : distancePresaleStart) % (1000 * 60 * 60)) /
          (1000 * 60)
      )
      this.countDown.seconds = Math.floor(
        ((presaleStart ? distancePresaleEnd : distancePresaleStart) % (1000 * 60)) / 1000
      )

      if (presaleStart || saleStart) {
        this.showMinting = true
      } else {
        this.showMinting = false
      }

      if (presaleStart) {
      } else if (saleStart) {
        this.showCounter = false
      }
    },
    formatNumber(number) {
      return number < 10 ? `0${number}` : number
    },
    async web3Handler() {
      await this.checkNetwork()

      this.allstarsContract = new this.web3.eth.Contract(abi, contractAddress)
      this.shelterContract = new this.web3.eth.Contract(shelterAbi, shelterContractAddress)

      const { allstarsContract, shelterContract, currentWallet, tokenID } = this

      const response = await Promise.all([
        currentWallet ? allstarsContract.methods.balanceOf(currentWallet, tokenID).call() : 0,
        currentWallet ? shelterContract.methods.balanceOf(currentWallet).call() : 0,
        allstarsContract.methods.isActive(tokenID).call(),
        allstarsContract.methods.getMaxSupply(tokenID).call(),
        allstarsContract.methods.getTotalSupply(tokenID).call(),
        currentWallet ? allstarsContract.methods.getPrice(tokenID, currentWallet).call() : 0
      ]).catch(error => {
        console.log(error)
        // setTimeout(() => {
        //   this.web3Handler()
        // }, 1000)
      })

      const [balance, shelterBalance, isActive, totalCount, mintedCount, price] = response

      this.isActive = isActive
      this.starBalance = Number(balance)
      this.shelterBalance = Number(shelterBalance)
      this.totalCount = Number(totalCount)
      this.mintedCount = Number(mintedCount)
      this.price = Number(price)

      if (this.currentCount === 0) {
        this.currentCount = this.maxMintCount > 1 ? 1 : this.maxMintCount
      }

      if (this.computedLeftCount <= 0) {
        this.soldOut = true
      }

      return Promise.resolve()
    },
    async isReadyToMint() {
      if (!this.isWalletConnected) {
        this.$emit('connectMetaMask')
        return Promise.resolve(false)
      }

      if (!this.currentWallet) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'MetaMask is not connected'
        })
        return Promise.resolve(false)
      }

      if (!this.isActive) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Smart contract paused'
        })
        return Promise.resolve(false)
      }

      if (this.currentcount > 1) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Max tokens are 1'
        })
        return Promise.resolve(false)
      }

      if (this.shelterBalance < 1) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Sorry! You need to mint M101 NFT first to get access to free mint of All-Star collection'
        })
        return Promise.resolve(false)
      }

      if (this.starBalance > 0) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Oops! You have already minted your 1 All-Star!'
        })
        return Promise.resolve(false)
      }

      if (this.currentcount > this.computedLeftCount) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Not enought tokens'
        })
        return Promise.resolve(false)
      }

      if (this.computedLeftCount <= 0) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'No tokens available'
        })
        return Promise.resolve(false)
      }

      return Promise.resolve(true)
    },
    async handleMint() {
      await this.web3Handler()

      const ready = await this.isReadyToMint()

      if (ready) {
        this.mint()
      }
    },
    mint() {
      const { allstarsContract, currentWallet, price, currentCount, web3Handler } = this

      allstarsContract.methods
        .mint(currentWallet, currentCount, 1)
        .send({
          from: currentWallet,
          value: currentCount * price
        })
        .on('transactionHash', hash => {
          this.title = `Your transaction`
          this.link = `<a href="https://etherscan.io/tx/${hash}" target="_blank">${hash}</a>`
          this.showModal = true
          web3Handler()
        })
        .on('error', error => {
          if (error.code !== 4001) {
            this.$notify({
              group: 'app-notifications',
              type: 'error',
              text: String(error).includes('eth_sendTransaction')
                ? 'Please connect to Ethereum Mainnet'
                : error
            })
          }
          web3Handler()
        })
    }
  }
}
</script>