<template>
  <header class="header bg-dark">
    <div class="container">
      <div v-if="!$root.isMobile && !$root.isTablet" class="header__content">
        <div class="header__sections">
          <div class="header__left">
            <a class="header__logo" href="#" @click.prevent="goTo('#main')">
              <svg width="91" height="90" aria-hidden="true" :style="{ fill: logoColor }">
                <use xlink:href="#logo"></use>
              </svg>
            </a>
            <div class="header__social-links">
              <a
                v-for="(link, i) in social_links"
                :key="i + 10"
                class="header__social-link"
                :href="link.href"
                target="_blank"
              >
                <svg width="40" height="40" aria-hidden="true">
                  <use :xlink:href="link.icon"></use>
                </svg>
              </a>
            </div>
          </div>
          <button class="button button--connect button--yellow" @click="connectMetaMask">
            <span></span><span></span><span></span><span></span><span></span><span></span>
            {{ connectButtonText }}
          </button>
        </div>
      </div>

      <div v-else class="header__content">
        <router-link class="header__logo" to="/">
          <svg width="91" height="90" aria-hidden="true" :style="{ fill: logoColor }">
            <use xlink:href="#logo"></use>
          </svg>
        </router-link>

        <div class="header__social-links">
          <a
            v-for="(link, i) in social_links"
            :key="i"
            class="header__social-link"
            :href="link.href"
            target="_blank"
          >
            <svg width="40" height="40" aria-hidden="true">
              <use :xlink:href="link.icon"></use>
            </svg>
          </a>
        </div>

        <button class="button button--toggle" :class="{ close: !menuOpened }" @click="menuToggle()">
          <img
            class="header__burger"
            :class="{ close: menuOpened }"
            src="@/assets/img/burger.svg"
            alt="burger menu"
          />
        </button>

        <transition name="slide-left">
          <div v-if="menuOpened" class="menu__content">
            <div class="header__sections bg-pink">
              <button class="button button--connect button--yellow" @click="connectMetaMask">
                <span></span><span></span><span></span><span></span><span></span><span></span>
                {{ connectButtonText }}
              </button>
            </div>
            <div class="header__copyright">
              <p>
                COPYRIGHT © 2021 <br />
                ALL RIGHTS RESERVED
              </p>
              <p><a href="mailto:survivors@m101.space">survivors@m101.space</a></p>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  components: {},
  props: {
    isWalletConnected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      menuOpened: false,
      social_links: [
        {
          href: 'https://twitter.com/M101_Shelter',
          icon: '#twitter'
        },
        {
          href: 'https://discord.com/invite/m101',
          icon: '#discord'
        },
        {
          href: 'https://opensea.io/collection/m101survivors',
          icon: '#opensea'
        }
      ]
    }
  },
  computed: {
    connectButtonText() {
      return this.isWalletConnected ? 'CONNECTED' : 'CONNECT WALLET'
    },
    logo() {
      return '@/assets/img/logo-white.svg'
    },
    logoColor() {
      return '#ffffff'
    }
  },
  mounted() {
    window.addEventListener('resize', this.menuClose)
    window.addEventListener('scroll', this.menuClose)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.menuClose)
    window.removeEventListener('scroll', this.menuClose)
  },
  created() {
    document.addEventListener('keyup', evt => {
      if (evt.keyCode === 27) {
        this.menuClose()
      }
    })
  },
  methods: {
    connectMetaMask() {
      this.$emit('connectMetaMask')
    },
    menuToggle() {
      this.menuOpened = !this.menuOpened
    },
    menuClose() {
      this.menuOpened = false
    },
    async goTo(link, offset) {
      if (link[0] === '/') {
        await this.$router.push({
          path: link
        })
        return
      }

      if (link === '#main' && this.$route.path === '/') {
        this.$scrollToTop()
        return
      }

      if (!offset) {
        offset = this.$root.isMobile || this.$root.isTablet ? -20 : -100
        if (link === '#races') {
          offset = 10
        }
      }

      if (!this.$scrollTo(link, offset)) {
        if (this.$route.path !== '/') {
          await this.$router.push({
            path: '/'
          })
          setTimeout(() => {
            this.$scrollTo(link, offset)
          }, 500)
        }
      }
    }
  }
}
</script>
