<template>
  <div class="minting__number">
    <button class="minting__number-btn minting__number-btn--left" @click="decrease">
      <svg width="56" height="56" aria-hidden="true">
        <use :xlink:href="'#arrow-btn'"></use>
      </svg>
    </button>
    <input ref="input" v-model.number="computedValue" type="number" class="minting__number-count" />
    <button class="minting__number-btn minting__number-btn--right" @click="increase">
      <svg width="56" height="56" aria-hidden="true">
        <use :xlink:href="'#arrow-btn'"></use>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'PlusMinusInput',
  props: {
    currentValue: {
      type: Number,
      required: true
    },
    maxValue: {
      type: Number,
      required: true
    },
    minValue: {
      type: Number,
      required: true
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.currentValue
      },
      set(val) {
        if (val >= this.minValue && val <= this.maxValue && Number.isInteger(val)) {
          this.$emit('update:currentValue', val)
        }
      }
    },
    isIncreaseDisabled() {
      return this.currentValue >= this.maxValue
    },
    isDecreaseDisabled() {
      return this.currentValue <= this.minValue
    }
  },
  mounted() {
    // this.$refs.input.focus()
  },
  methods: {
    decrease() {
      if (this.currentValue > this.minValue) {
        this.$emit('update:currentValue', this.currentValue - 1)
      }
    },
    increase() {
      if (this.currentValue < this.maxValue) {
        this.$emit('update:currentValue', this.currentValue + 1)
      }
    }
  }
}
</script>
