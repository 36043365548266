<template>
  <div id="main" class="section main bg-dark">
    <div class="main__bg-pattern"></div>
    <div class="container">
      <div id="mint" class="mint__content">
        <minting
          :current-wallet="currentWallet"
          :is-wallet-connected="isWalletConnected"
          @connectMetaMask="connectMetaMask"
        >
        </minting>
      </div>
      <div class="main__banner">
        <div class="main__banner-texts">
          <h2>
            NOT A M101 HOLDER? <br />
            GET YOUR M101 NFT <a href="https://m101.space" target="_blank">HERE</a> <br />
            FOR ONLY 0.08Ξ AND BECOME ELIGIBLE TO MINT FULL ALL-STAR COLLECTION!
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Minting from '@/components/Minting'

export default {
  name: 'Main',
  components: { Minting },
  props: {
    currentWallet: {
      type: String,
      default: null
    },
    isWalletConnected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      countDown: {
        startTimeSale: new Date('2021-10-28T23:00:00.000+03:00').getTime()
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    connectMetaMask() {
      this.$emit('connectMetaMask')
    }
  }
}
</script>
