import Vue from 'vue'
import App from './App.vue'
import Notifications from 'vue-notification'
import Helpers from './lib/Helpers.js'
import router from '@/router'
import VueMeta from 'vue-meta'
import Bowser from 'bowser'
import VueTypedJs from 'vue-typed-js'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/material.css'
import DynamicMarquee from 'vue-dynamic-marquee'

Vue.component('dynamic-marquee', DynamicMarquee)
Vue.component('VueSlider', VueSlider)

Vue.use(VueTypedJs)
Vue.use(Helpers)
Vue.use(Notifications)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false

window.appVue = new Vue({
  data() {
    return {
      window_top: 0,
      width: null,
      height: null,
      desctopMinBreakpoint: 1500,
      tabletBreakpoint: 1200,
      mobileBreakpoint: 768,
      bowser: null
    }
  },
  computed: {
    isDesctopMin() {
      return this.width <= this.desctopMinBreakpoint
    },
    isTablet() {
      return this.width <= this.tabletBreakpoint
    },
    isMobile() {
      return this.width <= this.mobileBreakpoint
    }
  },
  async created() {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.bowser = Bowser.parse(window.navigator.userAgent)
  },
  mounted() {
    this.addListeners()
  },
  methods: {
    addListeners() {
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.onResize)
      document.addEventListener('gesturestart', function (e) {
        e.preventDefault()
      })
    },
    onScroll(e) {
      this.window_top = window.pageYOffset
    },
    onResize(state) {
      this.width = window.innerWidth
      this.height = window.innerHeight
      document.body.classList.remove('scroll-off')
    }
  },
  router,
  render: h => h(App)
}).$mount('#app')
