<template>
  <main class="bg-main">
    <Header :is-wallet-connected="isWalletConnected" @connectMetaMask="connectMetaMask" />
    <section id="error-page" class="section error-page bg-dark">
      <div class="container">
        <div class="error-page__content">
          <img src="@/assets/img/error.png" alt="404" />
          <router-link class="button button--yellow" to="/">
            <span></span><span></span><span></span><span></span><span></span><span></span>
            Go to home page
          </router-link>
        </div>
      </div>
    </section>
    <Footer />
  </main>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'ErrorPage',
  components: { Header, Footer },
  props: {
    currentWallet: {
      type: String,
      default: null
    },
    isWalletConnected: {
      type: Boolean,
      required: true
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    connectMetaMask() {
      this.$emit('connectMetaMask')
    }
  }
}
</script>
